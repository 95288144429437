.card {
    background-color: white;  
    border-bottom: 100px solid #5c92e4
}
.card-image {
    height: 100%;
   

}
.card-content {

}