.banner {
  width: 100%;
  position: relative;

  

  .slide {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;

    transition: all 0.2s ease-out;
    display: none;
    &.active {
      display: block;
      .bg-image {
        animation: bg-anim 5s linear;
      }
      h2 {
        animation: title-anim 5s linear;
      }
    }
    overflow: hidden;
    .bg-image {
      width: 100%;
      height: 100%;
      filter: blur(1px) saturate(80%);
      transform: scale(1.06);
      top: -10px;
      position: absolute;
      background-size: cover;
      background-repeat: no-repeat;
    }
    .content {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      .title {
        font-family: 'Montserrat';
        //font-size: 3.5rem;
        text-transform: uppercase;
        span {
          display: inline-block;
          //padding: 0px 25px 5px 25px;
          color: #fff;
          margin: 3px;
          background-color: #623753ef;
          font-weight: 300;
          &:last-child {
            font-weight: 500;
          }
        }
      }
      h2 {
        //font-size: 2rem;
        background-color: #5c92e4;
        padding: 3px 20px 5px 20px;
        color: #fff;
        margin: 3px;
        overflow: hidden;
        white-space: nowrap;
        display: inline-block;
        letter-spacing: 2px;
        font-style: italic;
      }
    }
    .logo {
      display: none;
      position: absolute;
      right: 0;
      bottom: 20px;
      height: 70px;
      opacity: 0.9;
    }
  }
}

@keyframes bg-anim {
  0% {
    background-position: calc(50% + 20px) 0;
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    background-position: calc(50% - 20px) 0;
  }
}

@keyframes title-anim {
  0% {
    opacity: 0;
    transform: translateX(10px);
  }
  5% {
    opacity: 1;
    transform: translateX(0);
  }
  95% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    transform: translateX(-10px);
    opacity: 0;
  }
}

.logos {
  background-color: white;/* #e5e7eb;*/
  border: solid #69415b15;
  border-width: 0.5px 0;
  .container {
    display: grid;
    grid-template-columns: repeat(4, 25%);
    @media screen and (max-width: 750px) {
      grid-template-columns: repeat(1, 100%);
      margin: 30px 0;
      a {
        height: auto !important;
        img {
          position: relative !important;
          &.trenkwalder {
            margin-top: 30px;
          }
        }
      }
    }
    a {
      //flex: 1;
      position: relative;
      height: 110px;
      img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        &.http {
          max-height: 63px;
        }
        &.microsoft {
          height: 110px;
          max-height: 110px;
        }
        &.otp {
          max-height: 60px;
        }
        &.trenkwalder {
          max-height: 30px;
        }
      }
    }
  }
}
